import React, { useState } from 'react';
import i18n from './i18n';
import spAin from './assets/images/spain.png';
import unitedKingdo from './assets/images/united-kingdom.png';

const LanguageSelector = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language); // i18n.language contains the language assigned to lng in i18n.js file.

  const chooseLanguage = (e) => {
    i18n.changeLanguage(e); // i18n.changeLanguage() is used to change the language assigned to lng in i18n.js file.
    setSelectedLanguage(e);
    localStorage.setItem('lang', e);
    document.documentElement.lang = e;
  };

  return (
    <div className="dropdown lang_select_dropdown">
      <button
        type="button"
        className="lang_select_btn dropdown-toggle"
        data-toggle="dropdown"
      >
        <img
          className="flag-img"
          src={selectedLanguage == 'sp' ? spAin : unitedKingdo}
        />
        {selectedLanguage}
      </button>
      <div className="dropdown-menu">
        <div className="select-item" onClick={() => chooseLanguage('sp')}>
          <img className="flag-img" src={spAin} /> SP
        </div>
        <div className="select-item" onClick={() => chooseLanguage('en')}>
          <img className="flag-img" src={unitedKingdo} /> EN
        </div>
      </div>
    </div>
  );
};

export default LanguageSelector;
