import React, { useEffect, useRef, useState } from 'react';
import LanguageSelector from './LanguageSelector';
import { useTranslation } from 'react-i18next';
import WOW from 'wowjs';
import loGo from './assets/images/logo.png';
import sharingArticlesPana from './assets/images/sharing-articles-pana.svg';
import aboutImg from './assets/images/about_img2.png';
import joaquin from './assets/images/joaquin.jpg';
import facundo from './assets/images/facundo.jpg';
import antonio from './assets/images/antonio.jpg';
import laura from './assets/images/laura.jpg';
import ricard from './assets/images/ricard.jpg';
import marlenyFernandez from './assets/images/marleny-fernandez.jpg';
import roman from './assets/images/roman.jpg';
import tokenBg from './assets/images/token_bg.png';

import $ from 'jquery';

function App() {
  const [navToggler, setnavToggler] = useState(false);

  const headerToggleClass = () => {
    setnavToggler(!navToggler);
  };

  const { t } = useTranslation();
  const animationRef = useRef();
  const staggeredAnimationWrapRef = useRef();

  useEffect(() => {
    if (window.particlesJS) {
      window.particlesJS('banner_bg_effect', {
        particles: {
          number: {
            value: 80,
            density: {
              enable: true,
              value_area: 800,
            },
          },
          color: {
            value: '#ffffff',
          },
          shape: {
            type: 'circle',
            stroke: {
              width: 0,
              color: '#ffffff',
            },
            polygon: {
              nb_sides: 5,
            },
            image: {
              src: 'img/github.svg',
              width: 100,
              height: 100,
            },
          },
          opacity: {
            value: 0.4,
            random: false,
            anim: {
              enable: false,
              speed: 1,
              opacity_min: 0.5,
              sync: false,
            },
          },
          size: {
            value: 3,
            random: true,
            anim: {
              enable: false,
              speed: 20,
              size_min: 0.2,
              sync: false,
            },
          },
          line_linked: {
            enable: true,
            distance: 150,
            color: '#ffffff',
            opacity: 0.2,
            width: 1,
          },
          move: {
            enable: true,
            speed: 6,
            direction: 'none',
            random: false,
            straight: false,
            out_mode: 'out',
            bounce: false,
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
          },
        },
        interactivity: {
          detect_on: 'canvas',
          events: {
            onhover: {
              enable: true,
              mode: 'repulse',
            },
            onclick: {
              enable: true,
              mode: 'push',
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 400,
              line_linked: {
                opacity: 1,
              },
            },
            bubble: {
              distance: 400,
              size: 20,
              duration: 2,
              opacity: 1,
              speed: 3,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2,
            },
          },
        },
        retina_detect: true,
      });
    }
    window.$('.content-popup').magnificPopup({
      type: 'inline',
      preloader: true,
      mainClass: 'mfp-zoom',
    });

    $('a.page-scroll').on('click', function (event) {
      // On-page links
      if (
        window.location.pathname.replace(/^\//, '') ===
          this.pathname.replace(/^\//, '') &&
        window.location.hostname === this.hostname
      ) {
        // Figure out element to scroll to
        var target = $(this.hash),
          speed = $(this).data('speed') || 400;
        target = target.length
          ? target
          : $('[name=' + this.hash.slice(1) + ']');

        // Does a scroll target exist?
        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          event.preventDefault();
          $('html, body').animate(
            {
              scrollTop: target.offset().top - 60,
            },
            speed
          );
        }
      }
    });

    $('.scrollup').on('click', function (e) {
      e.preventDefault();
      $('html, body').animate(
        {
          scrollTop: 0,
        },
        600
      );
      return false;
    });

    window.$('.tk_countdown_time').each(function () {
      var endTime = $(this).data('time');
      window.$(this).countdown(endTime, function (tm) {
        window
          .$(this)
          .html(
            tm.strftime(
              '<span class="counter_box"><span class="tk_counter days">%D </span><span class="tk_text">Days</span></span><span class="counter_box"><span class="tk_counter hours">%H</span><span class="tk_text">Hours</span></span><span class="counter_box"><span class="tk_counter minutes">%M</span><span class="tk_text">Minutes</span></span><span class="counter_box"><span class="tk_counter seconds">%S</span><span class="tk_text">Seconds</span></span>'
            )
          );
      });
    });

    var config = {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: [29, 20, 15, 10.5, 9, 8, 4.5, 4],
            backgroundColor: [
              '#965ddc',
              '#78c596',
              '#f69040',
              '#0eadc9',
              '#d75db1',
              '#007bff',
              '#d7b75d',
              '#f17776',
            ],
            borderColor: [
              'rgba(255,255,255,0.5)',
              'rgba(255,255,255,0.5)',
              'rgba(255,255,255,0.5)',
              'rgba(255,255,255,0.5)',
              'rgba(255,255,255,0.5)',
              'rgba(255,255,255,0.5)',
              'rgba(255,255,255,0.5)',
            ],
            borderWidth: 1,
            label: 'Dataset 1',
          },
        ],
        labels: [
          'Future Architecture',
          'Liquidity Provision',
          'Ecosystem and Reward Pool',
          'Team',
          'Early Supporters',
          'Public Pre-sale',
          'Private Sale',
          'Advisors',
        ],
      },
      options: {
        responsive: true,
        legend: {
          display: false,
        },
        title: {
          display: false,
          text: 'Chart.js Doughnut Chart',
        },
        pieceLabel: {
          render: 'percentage',
          fontColor: [
            '#965ddc',
            '#78c596',
            '#f69040',
            '#0eadc9',
            '#d75db1',
            '#007bff',
            '#d7b75d',
            '#f17776',
          ],
          fontSize: 16,
          fontStyle: 'bold',
          position: 'outside',
          precision: 2,
        },
        animation: {
          animateScale: true,
          animateRotate: true,
        },
      },
    };
    const ctx = document.getElementById('token_sale').getContext('2d');
    const myPie = new window.Chart(ctx, config);

    window.$('.timeline').owlCarousel({
      loop: false,
      margin: 20,
      autoHeight: true,
      nav: true,
      navText: [
        '<i class="ion-ios-arrow-back"></i>',
        '<i class="ion-ios-arrow-forward"></i>',
      ],
      responsive: {
        0: {
          items: 1,
        },
        480: {
          items: 2,
        },
        767: {
          items: 3,
        },
        1000: {
          items: 4,
        },
        1199: {
          items: 4,
        },
      },
    });

    const wow = new WOW.WOW();
    wow.init();
  }, []);
  return (
    <div className="App">
      <header
        className={
          'header_wrap fixed-top wow fadeInDown ' + (navToggler && 'active')
        }
      >
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg">
            <a className="navbar-brand page-scroll" href="#home_section">
              <img
                className="logo_light main-logo"
                src={loGo}
                alt="logo"
                width="70"
              />
              <img
                className="logo_dark main-logo"
                src={loGo}
                alt="logo"
                width="70"
              />
            </a>
            <div className="d-flex">
              <div className="lang_select_dev mobile-only">
                <LanguageSelector />
              </div>
              <button
                className="navbar-toggler"
                type="button"
                onClick={headerToggleClass}
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="ion-android-menu"></span>
              </button>
            </div>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav m-auto">
                <li>
                  <a
                    className="nav-link page-scroll nav_item active"
                    href="#home_section"
                  >
                    {t('header.home')}
                  </a>
                </li>
                <li>
                  <a
                    className="nav-link page-scroll nav_item"
                    href="#what_we_do"
                  >
                    {t('header.what_and_how_we_do')}
                  </a>
                </li>
                <li>
                  <a
                    className="nav-link page-scroll nav_item"
                    href="#how_we_do"
                  >
                    {t('header.what_is_an_amatoken')}
                  </a>
                </li>
                <li>
                  <a className="nav-link page-scroll nav_item" href="#ama_coin">
                    {t('header.amatoken')}
                  </a>
                </li>
                <li>
                  <a className="nav-link page-scroll nav_item" href="#timeline">
                    {t('header.timeline')}
                  </a>
                </li>
                <li>
                  <a className="nav-link page-scroll nav_item" href="#faq">
                    {t('header.faq')}
                  </a>
                </li>
                <li>
                  <a className="nav-link page-scroll nav_item" href="#contact">
                    {t('header.contact')}
                  </a>
                </li>
              </ul>
              <div className="lang_select_dev desktop-only">
                <LanguageSelector />
              </div>
              <ul className="navbar-nav nav_btn align-items-center">
                <li>
                  <a
                    className="btn btn-default btn-radius nav_item"
                    href="https://ico.amatokencoin.com/login"
                    target="_blank"
                  >
                    Login
                  </a>
                </li>
                <li>
                  <a
                    className="btn btn-default btn-radius nav_item"
                    href="https://ico.amatokencoin.com/register"
                    target="_blank"
                  >
                    Register
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>

      <section
        id="home_section"
        className="section_banner bg_black_dark banner-section"
        data-z-index="1"
        data-parallax="scroll"
        data-image-src="assets/images/banner_bg2.png"
      >
        <div id="banner_bg_effect" className="banner_effect"></div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-12 col-sm-12 order-lg-first">
              <div className="banner_text_s2 text_md_center">
                <h1 className="text-white wow fadeInUp">
                  {t('banner_h1')}
                  <strong>{t('banner_h1_strong')}</strong>!
                </h1>
                <p className="presale_txt text-white wow fadeInUp">
                  {t('banner_p')}
                </p>
                <div className="btn_group pt-2 pb-3 wow fadeInUp">
                  <a href="#" className="btn btn-border btn-radius">
                    {t('banner_btn1')}
                    <i className="ion-ios-arrow-thin-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 order-first text-center">
              <div className="banner_image_right res_md_mb_50 res_xs_mb_30 wow fadeInRight">
                <img
                  alt="banner_vector2"
                  src={sharingArticlesPana}
                  width="450"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="what_we_do" className="small_pt">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="text_md_center">
                <img src={aboutImg} className="wow zoomIn" alt="aboutimg2" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 res_md_mt_30 res_sm_mt_20">
              <div className="title_default_light title_border">
                <h4 className="wow fadeInUp">{t('what_and_how_h4')}</h4>
                <p className="wow fadeInUp">{t('what_and_how_p1')}</p>
                <p className="wow fadeInUp">{t('what_and_how_p2')}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="how_we_do" className="section_team small_pt">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-md-12 offset-lg-1">
              <div className="title_default_light title_border text-center">
                <h4 className="wow fadeInUp">{t('what_is_an_amatoken_h4')}</h4>
                <p className="wow fadeInUp">{t('what_is_an_amatoken_p')}</p>
              </div>
            </div>
          </div>
          <div className="row small_space">
            <div className="col-lg-3 col-md-6 col-sm-6 res_md_mb_30 res_sm_mb_20">
              <div className="bg_light_dark radius_box team_box_s3 wow fadeInUp">
                <div className="text-center">
                  <img src={joaquin} alt="team1" className="team-pic" />
                  <div className="team_social_main">
                    <div className="team_social_s2 list_none">
                      <ul>
                        <li>
                          <a
                            href="https://www.linkedin.com/in/joaqu%C3%ADn-sabat%C3%A9-4058055/"
                            target="_blank"
                          >
                            <i className="fab fa-linkedin"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="team_info text-center">
                  <h4>
                    <a href="#team1" className="content-popup">
                      {t('members.m1.name')}
                    </a>
                  </h4>
                  <p className="team_p14">{t('members.m1.cat')}</p>
                </div>
                <div id="team1" className="team_pop mfp-hide">
                  <div className="row m-0">
                    <div className="col-md-4 text-center">
                      <div className="team_img_wrap">
                        <img
                          className="w-100"
                          src={joaquin}
                          alt="user_img-lg"
                        />
                        <div className="team_title">
                          <h4>{t('members.m1.name')}</h4>
                          <span>{t('members.m1.cat')}</span>
                        </div>
                      </div>
                      <div className="social_single_team list_none mt-3">
                        <ul>
                          <li>
                            <a
                              href="https://www.linkedin.com/in/joaqu%C3%ADn-sabat%C3%A9-4058055/"
                              target="_blank"
                            >
                              <i className="fab fa-linkedin"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="pt-3">
                        <h5>About</h5>
                        <hr />
                        <p>{t('members.m1.description')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 res_md_mb_30 res_sm_mb_20">
              <div className="bg_light_dark radius_box team_box_s3 wow fadeInUp">
                <div className="text-center">
                  <img src={facundo} alt="team2" className="team-pic" />
                  <div className="team_social_main">
                    <div className="team_social_s2 list_none">
                      <ul>
                        <li>
                          <a
                            href="https://www.linkedin.com/in/facundotedesco/"
                            target="_blank"
                          >
                            <i className="fab fa-linkedin"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="team_info text-center">
                  <h4>
                    <a href="#team2" className="content-popup">
                      {t('members.m2.name')}
                    </a>
                  </h4>
                  <p className="team_p14">{t('members.m2.cat')}</p>
                </div>
                <div id="team2" className="team_pop mfp-hide">
                  <div className="row m-0">
                    <div className="col-md-4 text-center">
                      <div className="team_img_wrap">
                        <img
                          className="w-100"
                          src={facundo}
                          alt="user_img-lg"
                        />
                        <div className="team_title">
                          <h4>{t('members.m2.name')}</h4>
                          <span>{t('members.m2.cat')}</span>
                        </div>
                      </div>
                      <div className="social_single_team list_none mt-3">
                        <ul>
                          <li>
                            <a
                              href="https://www.linkedin.com/in/facundotedesco/"
                              target="_blank"
                            >
                              <i className="fab fa-linkedin"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="pt-3">
                        <h5>About</h5>
                        <hr />
                        <p>{t('members.m2.description')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 res_md_mb_30 res_sm_mb_20">
              <div className="bg_light_dark radius_box team_box_s3 wow fadeInUp">
                <div className="text-center">
                  <img src={antonio} alt="team3" className="team-pic" />
                  <div className="team_social_main">
                    <div className="team_social_s2 list_none">
                      <ul>
                        <li>
                          <a
                            href="https://www.linkedin.com/in/antonio-corzo-676a184a/"
                            target="_blank"
                          >
                            <i className="fab fa-linkedin"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="team_info text-center">
                  <h4>
                    <a href="#team3" className="content-popup">
                      {t('members.m3.name')}
                    </a>
                  </h4>
                  <p className="team_p14">Compliance &amp; Rights Manager</p>
                </div>
                <div id="team3" className="team_pop mfp-hide">
                  <div className="row m-0">
                    <div className="col-md-4 text-center">
                      <div className="team_img_wrap">
                        <img
                          className="w-100"
                          src={antonio}
                          alt="user_img-lg"
                        />
                        <div className="team_title">
                          <h4>{t('members.m3.name')}</h4>
                          <span>{t('members.m3.cat')}</span>
                        </div>
                      </div>
                      <div className="social_single_team list_none mt-3">
                        <ul>
                          <li>
                            <a
                              href="https://www.linkedin.com/in/antonio-corzo-676a184a/"
                              target="_blank"
                            >
                              <i className="fab fa-linkedin"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="pt-3">
                        <h5>About</h5>
                        <hr />
                        <p>{t('members.m3.description')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 res_md_mb_30 res_sm_mb_20">
              <div className="bg_light_dark radius_box team_box_s3 wow fadeInUp">
                <div className="text-center">
                  <img src={laura} alt="team3" className="team-pic" />
                  <div className="team_social_main">
                    <div className="team_social_s2 list_none">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.linkedin.com/in/laura-s-61810631/"
                          >
                            <i className="fab fa-linkedin"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="team_info text-center">
                  <h4>
                    <a href="#team4" className="content-popup">
                      {t('members.m4.name')}
                    </a>
                  </h4>
                  <p className="team_p14">{t('members.m4.cat')}</p>
                </div>
                <div id="team4" className="team_pop mfp-hide">
                  <div className="row m-0">
                    <div className="col-md-4 text-center">
                      <div className="team_img_wrap">
                        <img className="w-100" src={laura} alt="user_img-lg" />
                        <div className="team_title">
                          <h4>{t('members.m4.name')}</h4>
                          <span>{t('members.m4.cat')}</span>
                        </div>
                      </div>
                      <div className="social_single_team list_none mt-3">
                        <ul>
                          <li>
                            <a
                              target="_blank"
                              href="https://www.linkedin.com/in/laura-s-61810631/"
                            >
                              <i className="fab fa-linkedin"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="pt-3">
                        <h5>About</h5>
                        <hr />
                        <p>{t('members.m4.description')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 res_md_mb_30 res_sm_mb_20">
              <div className="bg_light_dark radius_box team_box_s3 wow fadeInUp">
                <div className="text-center">
                  <img src={ricard} alt="team3" className="team-pic" />
                  <div className="team_social_main">
                    <div className="team_social_s2 list_none">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.linkedin.com/in/ricard-fideu-garcia-ba348910/"
                          >
                            <i className="fab fa-linkedin"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="team_info text-center">
                  <h4>
                    <a href="#team5" className="content-popup">
                      {t('members.m5.name')}
                    </a>
                  </h4>
                  <p className="team_p14">CTO</p>
                </div>
                <div id="team5" className="team_pop mfp-hide">
                  <div className="row m-0">
                    <div className="col-md-4 text-center">
                      <div className="team_img_wrap">
                        <img className="w-100" src={ricard} alt="user_img-lg" />
                        <div className="team_title">
                          <h4>{t('members.m5.name')}</h4>
                          <span>{t('members.m5.cat')}</span>
                        </div>
                      </div>
                      <div className="social_single_team list_none mt-3">
                        <ul>
                          <li>
                            <a
                              target="_blank"
                              href="https://www.linkedin.com/in/ricard-fideu-garcia-ba348910/"
                            >
                              <i className="fab fa-linkedin"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="pt-3">
                        <h5>About</h5>
                        <hr />
                        <p>{t('members.m5.description')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 res_md_mb_30 res_sm_mb_20">
              <div className="bg_light_dark radius_box team_box_s3 wow fadeInUp">
                <div className="text-center">
                  <img
                    src={marlenyFernandez}
                    alt="team3"
                    className="team-pic"
                  />
                  <div className="team_social_main">
                    <div className="team_social_s2 list_none">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.linkedin.com/in/marleny-fern%C3%A1ndez-8541592b6/"
                          >
                            <i className="fab fa-linkedin"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="team_info text-center">
                  <h4>
                    <a href="#team6" className="content-popup">
                      {t('members.m6.name')}
                    </a>
                  </h4>
                  <p className="team_p14">{t('members.m6.cat')}</p>
                </div>
                <div id="team6" className="team_pop mfp-hide">
                  <div className="row m-0">
                    <div className="col-md-4 text-center">
                      <div className="team_img_wrap">
                        <img
                          className="w-100"
                          src={marlenyFernandez}
                          alt="user_img-lg"
                        />
                        <div className="team_title">
                          <h4>{t('members.m6.name')}</h4>
                          <span>{t('members.m6.cat')}</span>
                        </div>
                      </div>
                      <div className="social_single_team list_none mt-3">
                        <ul>
                          <li>
                            <a
                              target="_blank"
                              href="https://www.linkedin.com/in/marleny-fern%C3%A1ndez-8541592b6/"
                            >
                              <i className="fab fa-linkedin"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="pt-3">
                        <h5>About</h5>
                        <hr />
                        <p>{t('members.m6.description')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 res_md_mb_30 res_sm_mb_20">
              <div className="bg_light_dark radius_box team_box_s3 wow fadeInUp">
                <div className="text-center">
                  <img src={roman} alt="team3" className="team-pic" />
                  <div className="team_social_main">
                    <div className="team_social_s2 list_none">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.linkedin.com/in/roman-de-vicente/"
                          >
                            <i className="fab fa-linkedin"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="team_info text-center">
                  <h4>
                    <a href="#team7" className="content-popup">
                      {t('members.m7.name')}
                    </a>
                  </h4>
                  <p className="team_p14">{t('members.m7.cat')}</p>
                </div>
                <div id="team7" className="team_pop mfp-hide">
                  <div className="row m-0">
                    <div className="col-md-4 text-center">
                      <div className="team_img_wrap">
                        <img className="w-100" src={roman} alt="user_img-lg" />
                        <div className="team_title">
                          <h4>{t('members.m7.name')}</h4>
                          <span>{t('members.m7.cat')}</span>
                        </div>
                      </div>
                      <div className="social_single_team list_none mt-3">
                        <ul>
                          <li>
                            <a
                              target="_blank"
                              href="https://www.linkedin.com/in/roman-de-vicente/"
                            >
                              <i className="fab fa-linkedin"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="pt-3">
                        <h5>About</h5>
                        <hr />
                        <p>{t('members.m7.description')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="ama_coin"
        className="section_token token_sale bg_light_dark"
        data-z-index="1"
        data-parallax="scroll"
        data-image-src={tokenBg}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3 col-md-12 col-sm-12">
              <div className="title_default_light title_border text-center">
                <h4 className="wow fadeInUp">
                  {t('amatoken_sale_section.heading')}
                </h4>
                <p className="wow fadeInUp">
                  {t('amatoken_sale_section.description')}
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-3">
              <div className="pr_box">
                <h6 className="wow fadeInUp">
                  {t('amatoken_sale_section.early_supporters_h')}
                </h6>
                <p className="wow fadeInUp">
                  {t('amatoken_sale_section.early_supporters_p')}
                </p>
              </div>
              <div className="pr_box">
                <h6 className="wow fadeInUp">
                  {t('amatoken_sale_section.private_sale_h')}
                </h6>
                <p className="wow fadeInUp">
                  {t('amatoken_sale_section.private_sale_p')}
                </p>
              </div>
              <div className="pr_box">
                <h6 className="wow fadeInUp">
                  {t('amatoken_sale_section.public_pre_sale_h')}
                </h6>
                <p className="wow fadeInUp">
                  {t('amatoken_sale_section.public_pre_sale_p')}
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="token_sale res_md_mb_40 res_md_mt_40 res_sm_mb_30 res_sm_mt_30">
                <div className="tk_countdown text-center token_countdown_bg">
                  <div className="tk_counter_inner">
                    <div
                      className="tk_countdown_time wow fadeInUp"
                      data-time="2021/12/08 00:00:00"
                    ></div>
                    <div className="progress wow fadeInUp">
                      <div
                        className="progress-bar progress-bar-striped gradient"
                        role="progressbar"
                        aria-valuenow="46"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: '46%' }}
                      >
                        {' '}
                        46%{' '}
                      </div>
                      <span
                        className="progress_label bg-white"
                        style={{ left: '30%' }}
                      >
                        {' '}
                        <strong> 46,000 BCC </strong>
                      </span>
                      <span
                        className="progress_label bg-white"
                        style={{ left: '75%' }}
                      >
                        {' '}
                        <strong> 90,000 BCC </strong>
                      </span>
                      <span className="progress_min_val">Sale Raised</span>
                      <span className="progress_max_val">Soft-caps</span>
                    </div>
                    <a
                      href="https://ico.amatokencoin.com/register"
                      target="_blank"
                      className="btn btn-default btn-radius wow fadeInUp"
                    >
                      Buy AMAToken <i className="ion-ios-arrow-thin-right"></i>
                    </a>
                    <ul className="icon_list list_none d-flex justify-content-center">
                      <li className="wow fadeInUp">
                        <i className="fa fa-cc-visa"></i>
                      </li>
                      <li className="wow fadeInUp">
                        <i className="fa fa-cc-mastercard"></i>
                      </li>
                      <li className="wow fadeInUp">
                        <i className="fa fa-bitcoin"></i>
                      </li>
                      <li className="wow fadeInUp">
                        <i className="fa fa-paypal"></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="pr_box">
                <h6 className="wow fadeInUp">
                  {t('amatoken_sale_section.public_sale_h')}
                </h6>
                <p className="wow fadeInUp">
                  {t('amatoken_sale_section.public_sale_p')}
                </p>
              </div>
              <div className="pr_box">
                <h6 className="wow fadeInUp">
                  {t('amatoken_sale_section.total_amatoken_sale_h')}
                </h6>
                <p className="wow fadeInUp">
                  {t('amatoken_sale_section.total_amatoken_sale_p')}
                </p>
              </div>
              <div className="pr_box">
                <h6 className="wow fadeInUp">
                  {t('amatoken_sale_section.acceptable_currency_h')}
                </h6>
                <p className="wow fadeInUp">
                  {t('amatoken_sale_section.acceptable_currency_p')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section_token token_sale overflow_hide">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-12 col-sm-12 res_md_mb_40">
              <div className="title_default_light title_border text-center">
                <h4 className="wow fadeInUp">
                  {t('amatoken_allocation.heading')}
                </h4>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-12">
              <div className="token_chart res_md_mb_30 res_sm_mb_20 text-center wow fadeInRight">
                <canvas id="token_sale"></canvas>
                <div className="chart_icon">
                  <img src={loGo} width="50" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <ul className="list_none chart_list wow fadeInLeft">
                <li>
                  <h6>
                    {t('amatoken_allocation.future_architecture.name')} -
                    {t('amatoken_allocation.future_architecture.value')}
                  </h6>
                  <div className="progress bg-965ddc">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: '29%' }}
                      aria-valuenow="29"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </li>
                <li>
                  <h6>
                    {t('amatoken_allocation.liquidity_provision.name')} -
                    {t('amatoken_allocation.liquidity_provision.value')}
                  </h6>
                  <div className="progress bg-green">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: '20%' }}
                      aria-valuenow="20"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </li>
                <li>
                  <h6>
                    {t('amatoken_allocation.ecosystem_and_reward_pool.name')} -
                    {t('amatoken_allocation.ecosystem_and_reward_pool.value')}
                  </h6>
                  <div className="progress bg-orange">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: '15%' }}
                      aria-valuenow="15"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </li>
                <li>
                  <h6>
                    {t('amatoken_allocation.team.name')} -
                    {t('amatoken_allocation.team.value')}
                  </h6>
                  <div className="progress bg-blue">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: '10.5%' }}
                      aria-valuenow="10.5"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </li>
                <li>
                  <h6>
                    {t('amatoken_allocation.early_supporters.name')} -
                    {t('amatoken_allocation.early_supporters.value')}
                  </h6>
                  <div className="progress bg-d75db1">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: '9%' }}
                      aria-valuenow="9"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </li>
                <li>
                  <h6>
                    {t('amatoken_allocation.public_pre_sale.name')} -
                    {t('amatoken_allocation.public_pre_sale.value')}
                  </h6>
                  <div className="progress bg-purple2">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: '8%' }}
                      aria-valuenow="8"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </li>
                <li>
                  <h6>
                    {t('amatoken_allocation.private_sale.name')} -
                    {t('amatoken_allocation.private_sale.value')}
                  </h6>
                  <div className="progress bg-yellow">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: '4.5%' }}
                      aria-valuenow="4.5"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </li>
                <li>
                  <h6>
                    {t('amatoken_allocation.advisors.name')} -
                    {t('amatoken_allocation.advisors.value')}
                  </h6>
                  <div className="progress bg-red">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: '4%' }}
                      aria-valuenow="4"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section id="timeline" className="bg_light_dark small_pb">
        <div className="container">
          <div className="row text-center">
            <div className="col-lg-8 col-md-12 offset-lg-2">
              <div className="title_default_light title_border text-center">
                <h4 className="wow fadeInUp">{t('timeline.heading')}</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="timeline owl-carousel small_space">
                <div className="item">
                  <div className="timeline_box complete">
                    <div className="timeline_inner">
                      <div className="timeline_circle"></div>
                      <h6 className="wow fadeInUp">
                        {t('timeline.february_2024.name')}
                      </h6>
                      <p className="wow fadeInUp">
                        {t('timeline.february_2024.des')}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="timeline_box complete current">
                    <div className="timeline_inner">
                      <div className="timeline_circle"></div>
                      <h6 className="wow fadeInUp">
                        {t('timeline.march_2024.name')}
                      </h6>
                      <p className="wow fadeInUp">
                        {t('timeline.march_2024.des')}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="timeline_box">
                    <div className="timeline_inner">
                      <div className="timeline_circle"></div>
                      <h6 className="wow fadeInUp">
                        {t('timeline.april_2024.name')}
                      </h6>
                      <p className="wow fadeInUp">
                        {t('timeline.april_2024.des')}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="timeline_box">
                    <div className="timeline_inner">
                      <div className="timeline_circle"></div>
                      <h6 className="wow fadeInUp">
                        {t('timeline.may_2024.name')}
                      </h6>
                      <p className="wow fadeInUp">
                        {t('timeline.may_2024.des')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="faq" className="bg_light_dark">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12 offset-lg-2">
              <div className="title_default_light title_border text-center">
                <h4 className="wow fadeInUp">
                  {t('have_any_questions.heading')}
                </h4>
                <p className="wow fadeInUp">
                  {t('have_any_questions.description')}
                </p>
              </div>
            </div>
          </div>
          <div className="row small_space justify-content-center">
            <div className="col-12 col-sm-12 col-md-9 col-lg-9">
              <div id="accordion1" className="faq_content">
                <div className="card wow fadeInUp">
                  <div className="card-header" id="headingOne">
                    <h6 className="mb-0">
                      <a
                        data-toggle="collapse"
                        href="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        {t('have_any_questions.q1.heading')}
                      </a>
                    </h6>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordion1"
                  >
                    <div className="card-body">
                      {t('have_any_questions.q1.answers')}
                    </div>
                  </div>
                </div>
                <div className="card wow fadeInUp">
                  <div className="card-header" id="headingTwo">
                    <h6 className="mb-0">
                      <a
                        className="collapsed"
                        data-toggle="collapse"
                        href="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        {t('have_any_questions.q2.heading')}
                      </a>
                    </h6>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordion1"
                  >
                    <div className="card-body">
                      {t('have_any_questions.q2.answers')}
                    </div>
                  </div>
                </div>
                <div className="card wow fadeInUp">
                  <div className="card-header" id="headingThree">
                    <h6 className="mb-0">
                      <a
                        className="collapsed"
                        data-toggle="collapse"
                        href="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        {t('have_any_questions.q3.heading')}
                      </a>
                    </h6>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordion1"
                  >
                    <div className="card-body">
                      {t('have_any_questions.q3.answers')}
                    </div>
                  </div>
                </div>
                <div className="card wow fadeInUp">
                  <div className="card-header" id="headingFour">
                    <h6 className="mb-0">
                      <a
                        className="collapsed"
                        data-toggle="collapse"
                        href="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        {t('have_any_questions.q4.heading')}
                      </a>
                    </h6>
                  </div>
                  <div
                    id="collapseFour"
                    className="collapse"
                    aria-labelledby="headingFour"
                    data-parent="#accordion1"
                  >
                    <div className="card-body">
                      {t('have_any_questions.q4.answers')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="contact" className="contact_section small_pt">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12 offset-lg-2">
              <div className="title_default_light title_border text-center">
                <h4 className="wow fadeInUp">{t('contact.heading')}</h4>
                <p className="wow fadeInUp">{t('contact.description')}</p>
              </div>
            </div>
          </div>
          <div className="row align-items-center small_space">
            <div className="col-lg-6 col-md-6">
              <div className="bg_light_dark  contact_box_s2">
                <div className="contact_title">
                  <h5 className="wow fadeInUp">
                    {t('contact.talk_with_us.heading')}
                  </h5>
                  <p className="wow fadeInUp">
                    {t('contact.talk_with_us.des')}
                  </p>
                </div>
                <ul className="list_none contact_info mt-margin">
                  <li className="wow fadeInUp">
                    <i className="ion-android-call"></i>
                    <div className="contact_detail">
                      <span>{t('contact.phone.heading')}</span>
                      <p>{t('contact.phone.number')}</p>
                    </div>
                  </li>
                  <li className="wow fadeInUp">
                    <i className="ion-ios-email"></i>
                    <div className="contact_detail">
                      <span>{t('contact.mail.heading')}</span>
                      <p>{t('contact.mail.id')}</p>
                    </div>
                  </li>
                </ul>
                <div className="contct_follow pt-2 pt-md-4">
                  <span className="text-uppercase wow fadeInUp">
                    {t('contact.follow_us.heading')}
                  </span>
                  <ul className="list_none social_icon">
                    <li className="wow fadeInUp">
                      <a href="https://twitter.com/AmaworldNFT" target="_blank">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li className="wow fadeInUp">
                      <a
                        href="https://www.instagram.com/AmaworldNFT"
                        target="_blank"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="pt-4 pt-md-0">
                <form method="post" name="enq" className="field_form">
                  <div className="row">
                    <div className="form-group col-md-12 wow fadeInUp">
                      <input
                        type="text"
                        required="required"
                        placeholder="Enter Name *"
                        id="first-name"
                        className="form-control"
                        name="name"
                      />
                    </div>
                    <div className="form-group col-md-12 wow fadeInUp">
                      <input
                        type="email"
                        required="required"
                        placeholder="Enter Email *"
                        id="email"
                        className="form-control"
                        name="email"
                      />
                    </div>
                    <div className="form-group col-md-12 wow fadeInUp">
                      <input
                        type="text"
                        required="required"
                        placeholder="Enter Subject"
                        id="subject"
                        className="form-control"
                        name="subject"
                      />
                    </div>
                    <div className="form-group col-md-12 wow fadeInUp">
                      <textarea
                        required="required"
                        placeholder="Message *"
                        id="description"
                        className="form-control"
                        name="message"
                        rows="2"
                      ></textarea>
                    </div>
                    <div className="col-md-12 text-center wow fadeInUp">
                      <button
                        type="submit"
                        title="Submit Your Message!"
                        className="btn btn-default btn-radius btn-block"
                        id="submitButton"
                        name="submit"
                        value="Submit"
                      >
                        Submit <i className="ion-ios-arrow-thin-right"></i>
                      </button>
                    </div>
                    <div className="col-md-12">
                      <div
                        id="alert-msg"
                        className="alert-msg text-center"
                      ></div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer>
        <div
          className="top_footer bg_light_dark"
          data-z-index="1"
          data-parallax="scroll"
          data-image-src="assets/images/footer_bg.png"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-12">
                <div className="footer_logo mb-3 wow fadeInUp">
                  <a href="#home_section" className="page-scroll">
                    <img alt="logo" src={loGo} width="60" />
                  </a>
                </div>
                <div className="footer_desc w-90">
                  <p className="wow fadeInUp">{t('footer.description')}</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 res_md_mt_30 res_sm_mt_20">
                <h4 className="footer_title border_title wow fadeInUp">
                  {t('footer.quick_links')}
                </h4>
                <ul className="footer_link list_arrow">
                  <li className="wow fadeInUp">
                    <a className="page-scroll" href="#what_we_do">
                      {t('header.what_and_how_we_do')}
                    </a>
                  </li>
                  <li className="wow fadeInUp">
                    <a className="page-scroll" href="#how_we_do">
                      {t('header.what_is_an_amatoken')}
                    </a>
                  </li>
                  <li className="wow fadeInUp">
                    <a className="page-scroll" href="#ama_coin">
                      {t('header.amatoken')}
                    </a>
                  </li>
                  <li className="wow fadeInUp">
                    <a className="page-scroll" href="#timeline">
                      {t('header.timeline')}
                    </a>
                  </li>
                  <li className="wow fadeInUp">
                    <a className="page-scroll" href="#faq">
                      {t('header.faq')}
                    </a>
                  </li>
                  <li className="wow fadeInUp">
                    <a className="page-scroll" href="#contact">
                      {t('header.contact')}
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-4 col-md-6 res_md_mt_30 res_sm_mt_20">
                <h4 className="footer_title border_title wow fadeInUp">
                  {t('footer.legal.heading')}
                </h4>
                <ul className="footer_link list_arrow">
                  <li className="wow fadeInUp">
                    <a
                      className="page-scroll"
                      href="./assets/images/legal-document.pdf"
                      target="_blank"
                    >
                      {t('footer.legal.menu1')}
                    </a>
                  </li>
                  <li className="wow fadeInUp">
                    <a className="page-scroll" href="#">
                      {t('footer.legal.menu2')}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom_footer">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <p className="copyright">{t('footer.copyright')}</p>
              </div>
              <div className="col-md-6">
                <ul className="list_none footer_menu">
                  <li>
                    <a href="#">{t('footer.privacy_policy')}</a>
                  </li>
                  <li>
                    <a href="#">{t('footer.terms_conditions')}</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-12">
                <p className="copyright">{t('footer.footer_amaworld')}</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <a href="#" className="scrollup btn-default">
        <i className="ion-ios-arrow-up"></i>
      </a>
    </div>
  );
}

export default App;
